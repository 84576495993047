import { Nav, Navbar, Button, Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, b2cPolicies } from '../../authConfig';
import './NavigationBar.css';
import React from "react";
import logo from '../../img/appiaLogo.png';


export const NavigationBar = () => {
    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };
    const handleSignupRedirect = () => {
        instance.loginRedirect(b2cPolicies.authorities.signUp)
    };


    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    const handleProfileEdit = () => {
        if (inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
        }
    };
    console.log(window.location.pathname)


    return (
        <>
            <AuthenticatedTemplate>
                <Navbar className="color-nav prices-nav" variant="light">
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <img
                                alt="logo da Appia"
                                src={logo}
                                width="50"
                                height="50"
                                className="d-inline-block align-top"

                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        <Nav.Link className={window.location.pathname === "/prices" || window.location.pathname === "/" ? "navbarButton active" : "navbarButton"} href="/prices">
                            Preços
                        </Nav.Link>
                        <Nav.Link className={window.location.pathname === "/buys" ? "navbarButton active" : "navbarButton"} href="/buys">
                            Pontos de compra
                        </Nav.Link>
                        <Nav.Link className={window.location.pathname === "/predictions" ? "navbarButton active" : "navbarButton"} href="/predictions">
                            Previsões
                        </Nav.Link>
                        <div className="collapse navbar-collapse justify-content-end ">

                            <DropdownButton align="end"
                                className="profileButton d-none d-sm-block"
                                id="dropdown-basic"
                                title={activeAccount && activeAccount.username ? activeAccount.username : "Unknown"}
                            >
                                <Dropdown.Item as="button" onClick={handleProfileEdit} className="profileButton">
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={handleLogoutRedirect} className="profileButton">
                                    Sair
                                </Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton align="end"
                                className="profileButton d-sm-none"
                                id="dropdown-basic"
                                title={<i className="fas fa-chevron-down"></i>}
                                style={{ maxWidth: "100%" }}
                            >
                                <Dropdown.Item as="button" onClick={handleProfileEdit} className="profileButton">
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={handleLogoutRedirect} className="profileButton">
                                    Sair
                                </Dropdown.Item>
                            </DropdownButton>

                        </div>
                    </Container>
                </Navbar >
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Navbar className="color-nav home-nav" variant="light" expand="sm">
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <img
                                alt="logo da Appia"
                                src={logo}
                                width="50"
                                height="50"
                                className="d-inline-block align-top"

                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        <Navbar.Collapse id="basic-navbar-nav" className="align-content-right">
                            <Nav className="mr-auto align-content-right">
                                <Nav.Link className={window.location.pathname === "/" ? "navbarButton active" : "navbarButton"} href="/">
                                    Início
                                </Nav.Link>
                                <Nav.Link className={window.location.pathname === "/about" ? "navbarButton active" : "navbarButton"} href="/about">
                                    Sobre
                                </Nav.Link>
                                <Nav.Link className={window.location.pathname === "/pricing" ? "navbarButton active" : "navbarButton"} href="/pricing">
                                    Preços
                                </Nav.Link>
                                <Nav.Link className={window.location.pathname === "/contact" ? "navbarButton active" : "navbarButton"} href="/contact">
                                    Contato
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="d-flex align-items-center justify-content-start">
                            <Button variant="success" className="custom-button text-sm" title="Sign In" onClick={handleLoginRedirect}>
                                Entrar
                            </Button>
                            <Button variant="success" className="custom-button text-sm" title="Sign Up" onClick={handleSignupRedirect}>
                                Cadastre-se
                            </Button>
                        </div>
                    </Container>
                </Navbar >
            </UnauthenticatedTemplate>
        </>
    );
};
