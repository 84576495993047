import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import './DateSpanSelector.css';
import { React } from 'react';

const DateSpanSelector = (props) => {

  const spans = ['1m', '3m', '6m', '1a', '5a', 'Max']
  const spansnoMax = ['1m', '3m', '6m', '1a', '5a']
  const dateSpan = [props.dateSpan]




  return (
    <ToggleButtonGroup className="date-span" type="radio" name="options2" value={dateSpan} onChange={props.changeDateSpan}>
      {props.notMax ?
        spansnoMax.map((span, key) => (
          <ToggleButton id={span} key={span.toString()} value={span}>{span}</ToggleButton>
        )) :
        spans.map((span, key) => (
          <ToggleButton id={span} key={span.toString()} value={span}>{span}</ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
}

export default DateSpanSelector;

