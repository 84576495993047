import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from '../navbar/NavigationBar';

export const PageLayout = (props) => {
    return (
        <>     
            
            <NavigationBar/>
            {props.children}

        </>
    );
};
