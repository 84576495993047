import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import StockSelector from '../components/StockSelector/StockSelector';
import UoMSelector from '../components/UoMSelector/UoMSelector';
import React, { useState, useEffect } from "react";
import { fetchAll, fetchJson } from '../utils/fetchHelpers';
import { filterStocks, filterUoMs, filterStocksbyUoM, prepareAll, extract_x_y_Chart } from '../utils/prepareData';
import DateSpanSelector from '../components/DateSpanSelector/DateSpanSelector';
import { LinePlotly } from '../components/Charts/Line_plotly';
import "../styles/Prices.css";
import { useNavigate } from "react-router-dom";


export const Prices = () => {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    let currentDate = new Date().toJSON().slice(0, 10);
    const navigate = useNavigate();

    //urls to fetch apis
    const stocksURL = "https://appiamktfdev.appia.com.br/api/getUsersStocks/" + activeAccount.localAccountId
    const stocksInfURL = "https://appiamktfdev.appia.com.br/api/getStocks"
    const pricesURL = "https://appiamktfdev.appia.com.br/api/prices/{stock}/2010-01-01/" + currentDate + "/?code=" + process.env.REACT_APP_APPIA_MARKET_KEY
    //variables to render elements
    const [stocksAvaiable, setStocksAvaiable] = useState([]);
    const [stocksSelected, setStocksSelected] = useState([]);
    const [stocksDownloaded, setStocksDowloaded] = useState([]);
    const [stocksInfo, setStocksInfo] = useState([]);

    const [uomAvaiable, setUomAvaiable] = useState([]);
    const [uomSelected, setUomSelected] = useState("R$/T");

    const [dateSpan, setDateSpan] = useState('1a');
    const [firstDate, setFirstDate] = useState([]);

    const [pricesPrep, setPricesPrep] = useState([]);
    const [pricesByDate, setPricesByDate] = useState([]);

    const [forbMessage, setForbMessage] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadedPrices, setLoadedPrices] = useState(false);
    const [errorFetch, setErrorFetch] = useState(false);
    const [errorPrices, setErrorPrices] = useState(false);


    useEffect(() => {


        const fetchData = async () => {
            
            try {
                
                const stocks = await fetchJson(stocksURL, 1);
                if (stocks.length === 0) {
                    setForbMessage(true)
                }

                const stocksInfoLS = JSON.parse(localStorage.getItem('stocksInfoLS'));
                const uomsLS = JSON.parse(localStorage.getItem('uomsLS'));
        

                if (stocksInfoLS && uomsLS) {
                
                    await setStocksInfo(stocksInfoLS);
                    await setUomAvaiable(uomsLS);
                    await setLoaded(true);
                   }else{

                const stocksInfo = await fetchJson(stocksInfURL, 1);
                setStocksInfo(stocksInfo)
                const filteredSI = await filterStocks(stocksInfo, stocks);
                setStocksInfo(filteredSI);
                await localStorage.setItem('stocksInfoLS', JSON.stringify(filteredSI));
                const uoms = await filterUoMs(stocksInfo);
                setUomAvaiable(uoms)
                await localStorage.setItem('uomsLS', JSON.stringify(uoms));
                //const prices = await fetchAll(stocks, pricesURL);
                //const pricesPrepared = await prepareAll(prices, 'Stock', 'Date', 'AdjClose');
                //await setPrices(pricesPrepared);
                //await localStorage.setItem('pricesLS', JSON.stringify(pricesPrepared));
                await setLoaded(true);
                   }}
            catch (e) {
                console.log(e);
                setErrorFetch(true);

            }

        }
        fetchData();
    }, [])


    useEffect(() => {
        const uoms = filterUoMs(stocksInfo);
        setUomAvaiable(uoms);
    }, [stocksInfo])

    useEffect(() => {
        setPricesPrep([]);
        setStocksDowloaded([]);
        setStocksSelected([]);
        setPricesByDate([]);
        const stocksAvaiable = filterStocksbyUoM(stocksInfo, uomSelected);
        setStocksAvaiable(stocksAvaiable);
    }, [uomAvaiable, uomSelected])

    useEffect(() => {

        const pricesSleDs = async () => {

            await setLoadedPrices(false);
            const difference = stocksSelected.filter((element) => !stocksDownloaded.includes(element));
            console.log("Difference", difference);
            const prices = await fetchAll(difference, pricesURL);
            const pricesPrepared = await prepareAll(prices, 'Stock', 'Date', 'AdjClose');

            const allPricesdup = pricesPrep.concat(pricesPrepared);
            const allPrices = [...new Set(allPricesdup.map(JSON.stringify))].map(JSON.parse);
            await setPricesPrep(allPrices);
            console.log(allPrices);

            const downloaded = JSON.parse(JSON.stringify(stocksDownloaded));
            const alldownloaded = downloaded.concat(difference);
            console.log(alldownloaded);
            await setStocksDowloaded(alldownloaded);
            
            
            //await localStorage.setItem('pricesLS', JSON.stringify(pricesPrepared));
            const firstDateExtractor = () => {
                const datedict = { '1m': 30, '3m': 90, '6m': 180, '1a': 365, '5a': 1826, 'Max': 5000 };
                let today = new Date();
                let days = 86400000 //number of milliseconds in a day
                let firstDate = new Date(today - (datedict[dateSpan] * days)).toJSON().slice(0, 10);
                return firstDate;
            }
            const firstDate = await firstDateExtractor();
            setFirstDate(firstDate);

            const filterStock = () => {

               
                const selectedPrices = allPrices.filter(price => {
                    price.data = price.data.filter(entry => entry.x >= "2010-01-01")
                    return stocksSelected.some((s) =>
                        price.id === s);
                })
                return selectedPrices
            }
            const selectedPrices = await filterStock();

            const filterDateSpan = () => {
                let arrayCopy = JSON.parse(JSON.stringify(selectedPrices));
                const selected = arrayCopy.filter(price => {
                    price.data = price.data.filter(entry => entry.x >= firstDate)
                    return price;
                })
                var filteredEmpty = selected.filter(function (el) { return el.data.length !== 0; });
                return filteredEmpty;
            }
            const selectedSpan = await filterDateSpan();
            selectedSpan.forEach(element => {
                element.data = extract_x_y_Chart(element.data)
            });
            setPricesByDate(selectedSpan);
            await setLoadedPrices(true);
        }
        try{
            pricesSleDs();
        }catch (e) {
            console.log(e);
            setErrorPrices(true);

        }

    }, [stocksSelected, dateSpan])

    useEffect(() => {
        console.log(pricesByDate);
    }, [pricesByDate])


    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    loaded ?
                        forbMessage === false ?
                            <Container fluid>
                                <Row className="align-items-center justify-content-between flex-row flex-wrap">
                                    <Col lg={3} xs={12} sm={12} md={9} className="flex-column myUomSelector">
                                        <div className="flex-grow-1">
                                            <UoMSelector
                                                uoms={uomAvaiable}
                                                uomSelected={uomSelected}
                                                changeUoM={(val) => setUomSelected(val)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={9} xs={12} className="date-span d-flex justify-content-start align-items-center ml-auto mt-2">
                                        <DateSpanSelector
                                            dateSpan={dateSpan}
                                            notMax={false}
                                            changeDateSpan={(val) => setDateSpan(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <StockSelector
                                        stocks={stocksAvaiable}
                                        checkbox={true}
                                        stocksSelected={stocksSelected}
                                        changeStocks={(val) => setStocksSelected(val)}
                                    />
                                </Row>
                                
                                {loadedPrices? (
                                    pricesByDate.length > 0 ?
                                    
                                    <LinePlotly prices={pricesByDate}/>
                        
                                    : null) : (
                                    <Container fluid className="d-flex justify-content-center mh-100" style={{ color: "#4040ff" }}>
                                <Spinner animation="border" />
                                </Container>)}
                        
                            </Container>

                            : <a>Nenhuma stock autorizada! </a>
                        :
                        errorFetch === false ?
                            <Container fluid className="d-flex justify-content-center mh-100" style={{ color: "#4040ff" }}>
                                <Spinner animation="border" />
                            </Container>
                            : <a>Erro ao carregar os recursos! </a>
                ) : null}
            </AuthenticatedTemplate>
        </>
    );
}      