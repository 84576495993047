
import React from "react";
import { useEffect, useState} from "react";
import Plot from 'react-plotly.js';
import {state} from './Line+Area_settings';


export const LineAreaPlotly = (props) => {

    const prices = props.prices;
    const preds = props.preds;

    const [dataPrep, setDataPrep] = useState([]);
    const [layoutPrep, setLayoutPrep] = useState([]);

    useEffect(()=>{
      let annotations = [];
      let annotation = {

        "x": 0,
        "y": 0,
        "xref": 'x',
        "yref": 'y',
        text: "",
        showarrow: false,
        arrowhead: 7,
        ax: 0,
        ay: -50};
      
      let data_prepared = JSON.parse(JSON.stringify(state.data));
      let an_prepared_max = JSON.parse(JSON.stringify(annotation));
      let an_prepared_min = JSON.parse(JSON.stringify(annotation));
      let an_prepared_last = JSON.parse(JSON.stringify(annotation));
      let an_prepared_last_pred = JSON.parse(JSON.stringify(annotation));


      let max_y = Math.max(...prices[1])
      let max_y_index = prices[1].indexOf(max_y);
      let max_x = prices[0][max_y_index];
      an_prepared_max.x = max_x
      an_prepared_max.y = max_y
      an_prepared_max.text = max_y.toFixed(2)
      an_prepared_max.showarrow = true
      annotations.push(an_prepared_max)
            
      let min_y = Math.min(...prices[1])
      let min_y_index = prices[1].indexOf(min_y);
      let min_x = prices[0][min_y_index];
      an_prepared_min.x = min_x
      an_prepared_min.y = min_y
      an_prepared_min.text = min_y.toFixed(2)
      an_prepared_min.showarrow = true
      an_prepared_min.ax = 0
      an_prepared_min.ay = 40
      annotations.push(an_prepared_min)

      let last_y = prices[1].slice(-1)[0]
      let last_x = prices[0].slice(-1)[0];
      an_prepared_last.x = last_x
      an_prepared_last.y = last_y
      an_prepared_last.text = last_y.toFixed(2)
      an_prepared_last.showarrow = true
      an_prepared_last.ax = 60
      an_prepared_last.ay = 0
      annotations.push(an_prepared_last)

      let lastpred_y = preds[0][1].slice(-1)[0];
      let lastpred_x = preds[0][0].slice(-1)[0];
      an_prepared_last_pred.x = lastpred_x
      an_prepared_last_pred.y = lastpred_y
      an_prepared_last_pred.text = lastpred_y.toFixed(2)
      an_prepared_last_pred.showarrow = true
      an_prepared_last_pred.ax = 0
      an_prepared_last_pred.ay = -50
      an_prepared_last_pred.xanchor =  "right";
      an_prepared_last_pred.yanchor =  "bottom";
      annotations.push(an_prepared_last_pred)
    
      data_prepared[0].x = prices[0]
      data_prepared[0].y = prices[1]
      data_prepared[1].x = preds[0][0]
      data_prepared[1].y = preds[0][1]
      data_prepared[2].x = preds[1][0]
      data_prepared[2].y = preds[1][1]
      data_prepared[3].x = preds[2][0]
      data_prepared[3].y = preds[2][1]
      setDataPrep(data_prepared)

      const layout_prepared = JSON.parse(JSON.stringify(state.layout));
      layout_prepared.xaxis.range = [preds[0][0][0], ...preds[0][0].slice(-1)]
      layout_prepared.annotations = annotations
      console.log(layout_prepared)
      setLayoutPrep(layout_prepared)

  },[preds, prices])
   return(
    <Plot
        data={dataPrep}
        layout={layoutPrep}
        useResizeHandler={true}
        config= {{displaylogo: false}}
        style={{width: '100%', height: '100%'}}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
      />
 );
}