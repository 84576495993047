// performs a request and resolves with JSON
/*export const fetchJson = async (url) => {

    const res = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*"}
      //mode: 'no-cors', 
      //credentials: 'include' 
    });
    if (!res.ok) {
      throw new Error(`${res.status}: ${res.text()}`);
    }
    return res.json();
  };*/

  export const fetchJson = async (url, retries) => {

    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true"}
      //headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*"}, 
      //mode: 'no-cors'
      //credentials: 'include' 
    });
    if (res.ok) {
      return res.json()
    }
    else if (retries > 0) {
  
      return setTimeout(() => {fetchJson(url, retries - 1)}, 3000);
    } else{
    throw new Error(res.status)
    }
  };

// fetch-helpers.js
  // get JSON from multiple URLs and pass to setters
export const fetchAll = (stocks, url) => {

    const requests = stocks.map((stock) => {
      const new_url = url.replace("{stock}", stock)
      return fetchJson(new_url, 1)
       .then((a) => {
        return a 
        })
    })
    return Promise.all(requests) // Aguardando até que todas as solicitações sejam resolvidas.
  };

  