
import React from "react";
import { useEffect, useState} from "react";
import Plot from 'react-plotly.js';
import {state} from './Line+Scatter_settings';


export const LineScatterPlotly = (props) => {

    const prices = props.prices;
    const buys = props.buys;


    const [dataPrep, setDataPrep] = useState([]);
    const [layoutPrep, setLayoutPrep] = useState([]);


    
    useEffect(()=>{

      const data_prepared = JSON.parse(JSON.stringify(state.data));
      if (buys.length !== 0) { 
        data_prepared[0].x = buys[0]
        data_prepared[0].y = buys[1]
        data_prepared[0].text = buys[1].map(function(element){
        return element.toFixed(2);});}
      
      data_prepared[1].x = prices[0]
      data_prepared[1].y = prices[1]
      setDataPrep(data_prepared)

      const layout_prepared = JSON.parse(JSON.stringify(state.layout));
      layout_prepared.xaxis.range = [prices[0][0], ...prices[0].slice(-1)]
      console.log(layout_prepared)
      setLayoutPrep(layout_prepared)

  },[prices, buys])
   return(
    <Plot
        data={dataPrep}
        layout={layoutPrep}
        useResizeHandler={true}
        config= {{displaylogo: false}}
        style={{width: '100%', height: '100%'}}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
      />
 );
}
