import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import StockSelector from '../components/StockSelector/StockSelector';
import UoMSelector from '../components/UoMSelector/UoMSelector';
import React, { useState, useEffect } from "react";
import { fetchAll, fetchJson } from '../utils/fetchHelpers';
import { filterStocks, filterUoMs, filterStocksbyUoM, prepareAllPred, prepareAll, extract_x_y_Chart, extract_x_y_ci } from '../utils/prepareData';
import DateSpanSelector from '../components/DateSpanSelector/DateSpanSelector';
import { firstDateExtractor, filterOnStock, filterDateSpan } from '../utils/filterUtils';
import { LineAreaPlotly } from '../components/Charts/Line+Area_plotly';


/***
 * Component to confirm authentication
 */
export const Predictions = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    let currentDate = new Date().toJSON().slice(0, 10);

    //urls to fetch apis
    const stocksURL = "https://appiamktfdev.appia.com.br/api/getUsersStocks/" + activeAccount.localAccountId
    const stocksInfURL = "https://appiamktfdev.appia.com.br/api/getStocks"
    const predictionsURL = 'https://appiamktfdev.appia.com.br/api/GetPrediction/{stock}/?code=' + process.env.REACT_APP_APPIA_MARKET_KEY
    const pricesURL = "https://appiamktfdev.appia.com.br/api/prices/{stock}/2010-01-01/" + currentDate + "/?code=" + process.env.REACT_APP_APPIA_MARKET_KEY
    const bestParamsURL = "https://appiamktfdev.appia.com.br/api/getBestParams/ALL" + "/?code=" + process.env.REACT_APP_APPIA_MARKET_KEY

    //variables to render elements
    const [stocks, setStocks] = useState([]);
    const [stocksInfo, setStocksInfo] = useState([]);
    const [stocksAvaiable, setStocksAvaiable] = useState([]);
    const [stocksSelected, setStocksSelected] = useState([]);
    const [noneSelected, setNoneSelected] = useState(false);
    const [stocksDownloaded, setStocksDowloaded] = useState([]);

    const [uomAvaiable, setUomAvaiable] = useState([]);
    const [uomSelected, setUomSelected] = useState('R$/T');


    const [dateSpan, setDateSpan] = useState('1a');

    const [predictions, setPredictions] = useState([])
    const [predsx_y, setPredsx_y] = useState([]);


    const [pricesPrep, setPricesPrep] = useState([]);
    const [pricesx_y, setPricesx_y] = useState([]);

    const [forbMessage, setForbMessage] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [emptyPreds, setEmptyPreds] = useState(false);
    const [loadedPrices, setLoadedPrices] = useState(false);
    const [errorFetch, setErrorFetch] = useState(false);
    const [errorPrices, setErrorPrices] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try{
            //fetch stockSelector and UoMSelector data
            
            const stocks = await fetchJson(stocksURL, 1);
            //const stocksWithModels = stocks.filter(value => modelsStocks.includes(value));
            const bestParams = await fetchJson(bestParamsURL, 1);
            const modelsStocks = bestParams.map( (item) => item.Stock);
            const stocksWithModels = stocks.filter(value => modelsStocks.includes(value));
            console.log(stocksWithModels);
            setStocks(stocksWithModels);

            if (stocks.length === 0) {
                setForbMessage(true)
            }
            const pricesLS = JSON.parse(localStorage.getItem('pricesLS'));
            const predsLS = JSON.parse(localStorage.getItem('predsLS'));

            const stocksInfo = await fetchJson(stocksInfURL, 1);
            setStocksInfo(stocksInfo)
            const filteredSI = await filterStocks(stocksInfo, stocksWithModels);
            setStocksInfo(filteredSI);
            const uoms = await filterUoMs(stocksInfo);
            setUomAvaiable(uoms);

            if (predsLS){
                setPredictions(predsLS);
            }else{
        
            const predictions = await fetchAll(stocksWithModels, predictionsURL);
            console.log(predictionsPrepared);
            const predictionsPrepared = await prepareAllPred(predictions, "Stock", "Date", "SarimaPredictedMean", "SarimaLower", "SarimaUpper");
            if (predictionsPrepared.length === 0){setEmptyPreds(true);}
            setPredictions(predictionsPrepared);
            
            await localStorage.setItem('predsLS', JSON.stringify(predictionsPrepared));

            }
            await setLoaded(true);
        }catch (e) {
            console.log(e);
            setErrorFetch(true);
        }
        }
        fetchData();

    }, [])
    useEffect(() => {
        const filteredSI = filterStocks(stocksInfo, stocks);
        setStocksInfo(filteredSI);
    }, [stocks])

    useEffect(() => {
        const uoms = filterUoMs(stocksInfo);
        setUomAvaiable(uoms);
    }, [stocksInfo])

    useEffect(() => {
        setPricesPrep([]);
        setStocksDowloaded([]);
        setStocksSelected([]);
        const stocksAvaiable = filterStocksbyUoM(stocksInfo, uomSelected);
        setStocksAvaiable(stocksAvaiable);
    }, [uomAvaiable, uomSelected])

    useEffect(() => {

        const pricesSleDs = async () => {
            await setLoadedPrices(false);
        
            if (!stocksDownloaded.includes(stocksSelected)) {
                //fetch data
                const prices = await fetchAll([stocksSelected], pricesURL);
                const pricesPrepared = await prepareAll(prices, 'Stock', 'Date', 'AdjClose');
                const allPricesdup = await pricesPrep.concat(pricesPrepared);
                const allPrices = [...new Set(allPricesdup.map(JSON.stringify))].map(JSON.parse);
                await setPricesPrep(allPrices);
                const downloaded = JSON.parse(JSON.stringify(stocksDownloaded));
                const alldownloaded = downloaded.concat(stocksSelected);
                
                await setStocksDowloaded(alldownloaded);
                const firstDate = await firstDateExtractor(dateSpan);
                const pricesStocks = await filterOnStock(allPrices, stocksSelected);
                const pricesDateSpan = await filterDateSpan(pricesStocks, firstDate);
                const pricesxy = await extract_x_y_Chart(pricesDateSpan[0].data);
            
                setPricesx_y(pricesxy);
            } else {
                const firstDate = await firstDateExtractor(dateSpan);
                const pricesStocks = await filterOnStock(pricesPrep, stocksSelected);
                const pricesDateSpan = await filterDateSpan(pricesStocks, firstDate);
                const pricesxy = await extract_x_y_Chart(pricesDateSpan[0].data);
                setPricesx_y(pricesxy);
            }
            const firstDate = await firstDateExtractor(dateSpan);
            const predictionsStocks = await filterOnStock(predictions, stocksSelected);
            const predictionsDateSpan = await filterDateSpan(predictionsStocks, firstDate);
            const predsxy = await extract_x_y_ci(predictionsDateSpan[0].data)
            setPredsx_y(predsxy);

            await setLoadedPrices(true);
            await setNoneSelected(false);

        }
        try{
            if(stocksSelected.length == 0){
                setNoneSelected(true);
            }else{
            pricesSleDs();}
        }catch (e) {
            console.log(e);
            setErrorPrices(true);

        }


    }, [stocksSelected, dateSpan])


    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    loaded ?
                        forbMessage === false ?
                        emptyPreds === false?
                            <Container fluid>
                                <Row className="align-items-center justify-content-between flex-row flex-wrap">
                                    <Col lg={3} xs={12} sm={12} md={9} className="flex-column myUomSelector">
                                        <div>
                                            <UoMSelector uoms={uomAvaiable}
                                                uomSelected={uomSelected}
                                                changeUoM={(val) => setUomSelected(val)} />
                                        </div>
                                    </Col>
                                    <Col lg={9} xs={12} className="date-span d-flex justify-content-start align-items-center ml-auto mt-2">
                                        <DateSpanSelector
                                            dateSpan={dateSpan}
                                            notMax={true}
                                            changeDateSpan={(val) => setDateSpan(val)} />
                                    </Col>
                                </Row>
                                <Row lg={12}>
                                    <StockSelector stocks={stocksAvaiable}
                                        checkbox={false}
                                        stocksSelected={stocksSelected}
                                        changeStocks={(val) => setStocksSelected(val)} />
                                </Row>
                                {noneSelected? 
                                null :
                                (loadedPrices? (
                                    <LineAreaPlotly preds={predsx_y}
                                        prices={pricesx_y} />
                                    ) : (
                                        <Container fluid className="d-flex justify-content-center mh-100" style={{ color: "#4040ff" }}>
                                    <Spinner animation="border" />
                                    </Container>))}
                            </Container>:
                                <p>Não há previsões disponíveis! </p>
                            :
                            <a>Nenhuma stock autorizada! </a>
                        :
                        errorFetch === false ?
                            <Container fluid className="d-flex justify-content-center mh-100" style={{ color: "#4040ff" }}>
                                <Spinner animation="border" />
                            </Container>
                            : <a>Erro ao carregar os recursos! </a>
                ) : null}
            </AuthenticatedTemplate>
        </>
    );
};