import { UnauthenticatedTemplate } from '@azure/msal-react';
import { Container,Row, Col  } from 'react-bootstrap';
import imagem1 from '../img/imagem1.jpg';
import imagem2 from '../img/imagem2.jpg';
import imagem3 from '../img/imagem3.jpg';
import './Sobre.css';


/***
 * Component to show "Sobre page"
 */
export const About = () => {

    return (
        <>
            <UnauthenticatedTemplate>
                
                <Container>
                <Container className="sobre-page">
        <Row>
          <Col>
            <h1>Compra Inteligente</h1>
            <p>
Identifique pontos de compra, com previsão de preços e análise de tendências.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <img src={imagem1} alt="Imagem 1" />
          </Col>
          <Col xs={4}>
            <img src={imagem2} alt="Imagem 2" />
          </Col>
          <Col xs={4}>
            <img src={imagem3} alt="Imagem 3" />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div className="texto-caixa">
            <strong className="titulo-caixa">Comparativo de Preços</strong>
              <p>Compare os preços de várias fontes, com unidades de medida adaptadas, incluindo histórico de mercados nacionais e internacionais.</p>
            </div>
          </Col>
          <Col xs={4}>
            <div className="texto-caixa">
            <strong className="titulo-caixa">Pontos de Compra</strong>
              <p>Tenha alertas diários de indicações de compra ou não-compra, com reaprendizado contínuo.</p>
            </div>
          </Col>
          <Col xs={4}>
            <div className="texto-caixa">
            <strong className="titulo-caixa">Previsão de Preços</strong>
              <p>Previsão de preços futuros atualizada periodicamente, com análise de tendência do mercado.</p>
            </div>
          </Col>
        </Row>
      </Container>
                </Container>

            </UnauthenticatedTemplate>
        </>
    );
};