// filter StocksInfo based on stocks authorized
export const filterStocks = (stocksInfo, stocks) => {
            let authorized = []
            stocksInfo.forEach(function(object){
              if(stocks.includes(object["Stock"])){
                authorized.push(object)
              }
            })
        return authorized
        }

export const filterUoMs = (stocksInfo) => {
        let avaiable = []
         stocksInfo.forEach(function(object){
          if (!avaiable.includes(object["UoM"])) {
            avaiable.push(object["UoM"]);
          }
          })
        return avaiable
        }
export const filterStocksbyUoM = (stocksInfo, uom) =>{
  let avaiable = []
  stocksInfo.forEach(function(object){
    if (object['UoM'] === uom){
      avaiable.push(object['Stock'])
    }
  })
  return avaiable
}

const prepareData = (array, id, x, y) =>{

  let objectofPrices = {}
  objectofPrices["id"] = array[0][id];
  objectofPrices['data'] = [];
  array.forEach(function(object){
  let new_entry = {};
  new_entry['x'] = object[x].slice(0, 10);
  new_entry['y'] = object[y];
  objectofPrices.data.push(new_entry);
  })
return objectofPrices;
}

export const prepareAll = (array, id, x, y) =>{
let new_array = []
array.forEach(function (item) {
  if (item.length !== 0) {
  new_array.push(prepareData(item, id, x, y));
  }
});
return new_array;
}

export const filterStocksBB = (array) => {
  let filteredBB= [];
  let filteredStocks= [];
  array.forEach(element => 
    {if(element.length > 0){
      filteredBB.push(element)
      filteredStocks.push(element[0].Stock)
    }
    });
      return [filteredBB,filteredStocks];
}
const preparePred = (array, id, x, y, low, high) =>{

  let objectofPrices = {}
  objectofPrices["id"] = array[0][id];
  objectofPrices['data'] = [];
  array.forEach(function(object){
  let new_entry = {};
  new_entry['x'] = object[x].slice(0, 10);
  new_entry['y'] = object[y];
  new_entry['low'] = object[low];
  new_entry['high'] = object[high];
  objectofPrices.data.push(new_entry);
  })
return objectofPrices;
}

export const prepareAllPred = (array, id, x, y, low, high) =>{
  let new_array = []
  array.forEach(function (item) {
    if (item.length !== 0) {
    new_array.push(preparePred(item, id, x, y, low, high));
    }
  });
  return new_array;
  }

export const joinPrednPrices = (preds, prices) =>{
  //prepare area plot
  let objectofPrices = {}
  objectofPrices["id"] = prices[0]['id'] + " Preço";
  objectofPrices['data'] = [];
  prices[0].data.forEach(function(object){
  let new_entry = {};
  new_entry['x'] = object['x'].slice(0, 10);
  new_entry['y'] = object['y'];
  new_entry['low'] = -200;
  new_entry['high'] = -200;
  objectofPrices.data.push(new_entry);
  })
  let finalArray = preds.concat(objectofPrices)
  finalArray[0].id = finalArray[0].id + " Predição"

  return finalArray
}

export const extract_x_y_Chart = (array_data) =>{
  let x = [];
  let y = [];
  array_data.forEach(element => {
    x.push(element['x'])
    y.push(element['y'])
  });
  return [x,y];
}

export const extract_x_y_ci = (array_data) =>{
  let x_pred = [];
  let y_pred = [];
  let x_high = [];
  let y_high = [];
  let x_low = [];
  let y_low = [];

  array_data.forEach(element => {
    x_pred.push(element['x'])
    y_pred.push(element['y'])
    x_high.push(element['x'])
    y_high.push(element['high'])
    x_low.push(element['x'])
    y_low.push(element['low'])
  });
  return [[x_pred,y_pred], [x_high, y_high], [x_low, y_low]];
}


