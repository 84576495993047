import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import './UoMSelector.css'


const UoMSelector = (props) => {

  let uoms = props.uoms
  const uomSelected = [props.uomSelected]

  return (

    <ToggleButtonGroup type="radio" name='options' value={uomSelected} onChange={props.changeUoM} className="myUomSelector">
      {uoms.map((uom, key) => (
        <ToggleButton id={uom} key={key.toString()} value={uom}>{uom}</ToggleButton>
      ))}
    </ToggleButtonGroup>

  );
}

export default UoMSelector;