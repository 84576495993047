import React from "react";
import { useEffect, useState} from "react";
import Plot from 'react-plotly.js';
import {state} from './Line_settings';


export const LinePlotly = (props) => {

    const prices = props.prices;

    const [dataPrep, setDataPrep] = useState([]);
    const [layoutPrep, setLayoutPrep] = useState([]);

    
    
    useEffect(()=>{
        let annotations = [];
        let data = [];
        let trace =
        { 
        "name": "",
        "line": {
            "dash": "solid",
            "shape": "linear",
            "width": 3
                },
        "fill": "none",
        "mode": "lines",
        "type": "scatter",

        "x": [],
        "y": [],
        "text": [],

        "visible": true,
        "showlegend": true,
        "textposition": "bottom center",
        "connectgaps": true
        }
        let annotation = {

                "x": 0,
                "y": 0,
                "xref": 'x',
                "yref": 'y',
                text: "",
                showarrow: true,
                arrowhead: 7,
                ax: 0,
                ay: -50
        }


        prices.forEach(price => {
            let trace_prepared = JSON.parse(JSON.stringify(trace));
            let an_prepared_max = JSON.parse(JSON.stringify(annotation));
            let an_prepared_min = JSON.parse(JSON.stringify(annotation));
            let an_prepared_last = JSON.parse(JSON.stringify(annotation));
            trace_prepared.name = price.id;

            let max_y = Math.max(...price.data[1])
            let max_y_index = price.data[1].indexOf(max_y);
            let max_x = price.data[0][max_y_index];
            an_prepared_max.x = max_x
            an_prepared_max.y = max_y
            an_prepared_max.text = max_y.toFixed(2)
            
            let min_y = Math.min(...price.data[1])
            let min_y_index = price.data[1].indexOf(min_y);
            let min_x = price.data[0][min_y_index];
            an_prepared_min.x = min_x
            an_prepared_min.y = min_y
            an_prepared_min.text = min_y.toFixed(2)

            let last_y = price.data[1].slice(-1)[0];

            let last_x = price.data[0].slice(-1)[0];
            an_prepared_last.x = last_x;
            an_prepared_last.y = last_y;
            an_prepared_last.text = last_y.toFixed(2);
            an_prepared_last.xanchor =  "right";
            an_prepared_last.yanchor =  "bottom";

            
            trace_prepared.x = price.data[0];
            trace_prepared.y = price.data[1];
            data.push(trace_prepared);
            annotations.push(an_prepared_min)
            annotations.push(an_prepared_max)
            annotations.push(an_prepared_last)
        });

      setDataPrep(data)

      let layout_prepared = JSON.parse(JSON.stringify(state.layout));
      layout_prepared.xaxis.range = [prices[0].data[0][0], ...prices[0].data[0].slice(-1)]
      layout_prepared.annotations = annotations
      
      setLayoutPrep(layout_prepared)

  },[prices])
   return(
    <Plot
        data={dataPrep}
        layout={layoutPrep}
        useResizeHandler={false}
        style={{width: '100%', height: '100%'}}
        config= {{displaylogo: false, responsive: true}}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
      />
 );
}
