import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import React, { useState } from "react";
import './StockSelector.css'

const StockSelector = (props) => {

  const stocksSelected = props.stocksSelected
  const checkbox = props.checkbox

  return (

    checkbox ?

      <ToggleButtonGroup type="checkbox" value={stocksSelected} onChange={props.changeStocks} className="myStockSelector">
        {props.stocks.map((stock) => (
          <ToggleButton id={stock} key={stock} value={stock}>{stock}</ToggleButton>
        ))}
      </ToggleButtonGroup>

      :

      <ToggleButtonGroup type="radio" name="StockOptions" value={stocksSelected} onChange={props.changeStocks} className="myStockSelector">
        {props.stocks.map((stock) => (
          <ToggleButton id={stock} key={stock} value={stock}>{stock}</ToggleButton>
        ))}
      </ToggleButtonGroup>

  );
}

export default StockSelector;
