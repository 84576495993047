import { UnauthenticatedTemplate } from '@azure/msal-react';
import { Container } from 'react-bootstrap';

/***
 * Component to confirm authentication
 */
export const Pricing = () => {

    return (
        <>
            <UnauthenticatedTemplate>
                
                <Container>
                    <p>Componente com Pricing</p>
                </Container>

            </UnauthenticatedTemplate>
        </>
    );
};