import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import StockSelector from '../components/StockSelector/StockSelector';
import UoMSelector from '../components/UoMSelector/UoMSelector';
import React, { useState, useEffect } from "react";
import { fetchAll, fetchJson } from '../utils/fetchHelpers';
import { filterStocks, filterUoMs, filterStocksbyUoM, prepareAll, filterStocksBB, extract_x_y_Chart } from '../utils/prepareData';
import DateSpanSelector from '../components/DateSpanSelector/DateSpanSelector';
import { firstDateExtractor, filterOnStock, filterDateSpan } from '../utils/filterUtils';

import { LineScatterPlotly } from '../components/Charts/Line+Scatter_plotly';


/***
 * Component to confirm authentication
 */
export const Buys = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    let currentDate = new Date().toJSON().slice(0, 10);
    

    //urls to fetch apis
    const stocksURL = "https://appiamktfdev.appia.com.br/api/getUsersStocks/" + activeAccount.localAccountId
    const stocksInfURL = "https://appiamktfdev.appia.com.br/api/getStocks"
    const pricesURL = "https://appiamktfdev.appia.com.br/api/prices/{stock}/2010-01-01/" + currentDate + "/?code=" + process.env.REACT_APP_APPIA_MARKET_KEY
    const bestBuyURL = 'https://appiamktfdev.appia.com.br/api/GetBestBuy/{stock}/?code=' + process.env.REACT_APP_APPIA_MARKET_KEY

    //variables to render elements
    const [stocks, setStocks] = useState([]);
    const [stocksInfo, setStocksInfo] = useState([]);
    const [stocksAvaiable, setStocksAvaiable] = useState([]);
    const [stocksSelected, setStocksSelected] = useState([]);
    const [stocksDownloaded, setStocksDowloaded] = useState([]);
    const [noneSelected, setNoneSelected] = useState(false);

    const [uomAvaiable, setUomAvaiable] = useState([]);
    const [uomSelected, setUomSelected] = useState('R$/T');

    const [dateSpan, setDateSpan] = useState('1a');

    const [pricesPrep, setPricesPrep] = useState([])
    const [pricesByDate, setPricesByDate] = useState([]);
    const [pricesx_y, setPricesx_y] = useState([]);

    const [buySignals, setBuySignals] = useState([]);
    const [buyByDate, setBuyByDate] = useState([]);
    const [buyx_y, setBuyx_y] = useState([]);

    const [forbMessage, setForbMessage] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [emptySignals, setEmptySignals] = useState(false);
    const [loadedPrices, setLoadedPrices] = useState(false);
    const [errorFetch, setErrorFetch] = useState(false);
    const [errorPrices, setErrorPrices] = useState(false);
    useEffect(() => {
        const fetchData = async () => {

            
            try{
            //fetch stockSelector and UoMSelector data
            const stocks = await fetchJson(stocksURL, 1);
            setStocks(stocks);
            if (stocks.length === 0) {
                setForbMessage(true)
            }
            const stocksInfo = await fetchJson(stocksInfURL, 1);
            setStocksInfo(stocksInfo)
            const filteredSI = await filterStocks(stocksInfo, stocks);
            setStocksInfo(filteredSI);
            const uoms = await filterUoMs(stocksInfo);
            setUomAvaiable(uoms);
            //fetch and prepare prices data
            /* if (pricesBuy){
                setPrices(pricesBuy);
                console.log(pricesBuy)
            }else{
                const prices = await fetchAll(stocks, pricesURL);
                const pricesPrepared = await prepareAll(prices, "Stock", "Date", "AdjClose");
                setPrices(pricesPrepared);
                console.log(pricesPrepared);
                await localStorage.setItem('pricesLS', JSON.stringify(pricesPrepared));

            } */
            //fetch and prepare bestBuys data
            const bestBuys = await fetchAll(stocks, bestBuyURL);
            const filteredBB = await filterStocksBB(bestBuys);
            setStocks(filteredBB[1]);

            const bestBuysPrep = await prepareAll(filteredBB[0], "Stock", "Date", "RSIBuy")
            setBuySignals(bestBuysPrep)

            setLoaded(true);
            if (bestBuysPrep.length === 0){setEmptySignals(true);}
            }
            catch (e) {
                console.log(e);
                setErrorFetch(true);

            }

        }

        fetchData();

    }, [])
    useEffect(() => {
        const filteredSI = filterStocks(stocksInfo, stocks);
        setStocksInfo(filteredSI);
    }, [stocks])

    useEffect(() => {
        const uoms = filterUoMs(stocksInfo);
        setUomAvaiable(uoms);
    }, [stocksInfo])

    useEffect(() => {
        setPricesPrep([]);
        setStocksDowloaded([]);
        setStocksSelected([]);
        setPricesByDate([]);
        const stocksAvaiable = filterStocksbyUoM(stocksInfo, uomSelected);
        setStocksAvaiable(stocksAvaiable);
    }, [uomAvaiable, uomSelected])

    useEffect(() => {

        const pricesSleDs = async () => {
    
            await setLoadedPrices(false);
            if (!stocksDownloaded.includes(stocksSelected)) {
                //fetch data
                const prices = await fetchAll([stocksSelected], pricesURL);
                const pricesPrepared = await prepareAll(prices, 'Stock', 'Date', 'AdjClose');
                const allPricesdup = await pricesPrep.concat(pricesPrepared);
                const allPrices = [...new Set(allPricesdup.map(JSON.stringify))].map(JSON.parse);
                await setPricesPrep(allPrices);
                const downloaded = JSON.parse(JSON.stringify(stocksDownloaded));
                const alldownloaded = downloaded.concat(stocksSelected);
                
                await setStocksDowloaded(alldownloaded);
                const firstDate = await firstDateExtractor(dateSpan);
                const pricesStocks = await filterOnStock(allPrices, stocksSelected);
                const pricesDateSpan = await filterDateSpan(pricesStocks, firstDate);
                const pricesxy = await extract_x_y_Chart(pricesDateSpan[0].data);
                setPricesByDate(pricesDateSpan);
                setPricesx_y(pricesxy);
            } else {
                const firstDate = await firstDateExtractor(dateSpan);
                const pricesStocks = await filterOnStock(pricesPrep, stocksSelected);
                const pricesDateSpan = await filterDateSpan(pricesStocks, firstDate);
                const pricesxy = await extract_x_y_Chart(pricesDateSpan[0].data);
                setPricesByDate(pricesDateSpan);
                setPricesx_y(pricesxy);
            }
        
            const firstDate = await firstDateExtractor(dateSpan);
            const buysStocks = await filterOnStock(buySignals, stocksSelected);
            const buysDateSpan = await filterDateSpan(buysStocks, firstDate);

            if (buysDateSpan.length === 0) { 
                const buyxy = new Array();
                setBuyx_y(buyxy);
             }else{
                const buyxy = await extract_x_y_Chart(buysDateSpan[0].data);
                setBuyByDate(buysDateSpan);
                setBuyx_y(buyxy);
             };
             await setLoadedPrices(true);
             await setNoneSelected(false);

        };
        try{
            if(stocksSelected.length == 0){
                setNoneSelected(true);
            }else{
            pricesSleDs();}
        }catch (e) {
            console.log(e);
            setErrorPrices(true);

        }
    }, [stocksSelected, dateSpan])


    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    loaded ?
                        forbMessage === false ?
                            emptySignals === false?
                            <Container fluid>
                                
                                <Row className="align-items-center justify-content-between flex-row flex-wrap">
                                    <Col lg={3} xs={12} sm={12} md={9} className="flex-column myUomSelector">
                                        <div className="flex-grow-1">
                                            <UoMSelector uoms={uomAvaiable}
                                                uomSelected={uomSelected}
                                                changeUoM={(val) => setUomSelected(val)} />
                                        </div>
                                    </Col>
                                    <Col lg={9} xs={12} className="date-span d-flex justify-content-start align-items-center ml-auto mt-2">
                                        <DateSpanSelector
                                            dateSpan={dateSpan}
                                            notMax={true}
                                            changeDateSpan={(val) => setDateSpan(val)} />
                                    </Col>
                                </Row>
                                <Row lg={12}>
                                    <StockSelector stocks={stocksAvaiable}
                                        checkbox={false}
                                        stocksSelected={stocksSelected}
                                        changeStocks={(val) => setStocksSelected(val)} />
                                </Row>
                                
                                {noneSelected?
                                null : 
                                (loadedPrices? (
                                    pricesByDate.length > 0 ?
                                    
                                    <LineScatterPlotly prices={pricesx_y}
                                        buys={buyx_y} />
                                    : null) : (
                                    <Container fluid className="d-flex justify-content-center mh-100" style={{ color: "#4040ff" }}>
                                <Spinner animation="border" />
                                </Container>))}
                            </Container>
                                :
                                <p>Não há pontos de compra disponíveis! </p>
                            :
                            <p>Nenhuma stock autorizada! </p>
                        :
                        errorFetch === false ?
                            <Container fluid className="d-flex justify-content-center mh-100" style={{ color: "#4040ff" }}>
                                <Spinner animation="border" />
                            </Container>
                            : <a>Erro ao carregar recursos!</a>
                ) : null}
            </AuthenticatedTemplate>

        </>
    );
};
/*{buyByDate.length > 0?
    <LineScatterChart data = {pricesByDate}
    dataScatter = {buyByDate} />
    :null
    }*/