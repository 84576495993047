export const firstDateExtractor = (dateSpan) =>{
    const datedict = {'1m': 30, '3m': 90, '6m': 180, '1a': 365, '5a': 1826 , 'Max': 5000};
    let today = new Date();
    let days = 86400000 //number of milliseconds in a day
    let firstDate = new Date(today - (datedict[dateSpan]*days)).toJSON().slice(0, 10);
    return firstDate;
}

export const filterOnStock = (data, stocksSelected) => {
    let dataCopy = JSON.parse(JSON.stringify(data));
    const dataFiltered = dataCopy.filter(element => {
    element.data = element.data.filter(entry => entry.x >= "2010-01-01")
    return element.id === stocksSelected;
    })
    return dataFiltered}

export const filterDateSpan =(data, firstDate)=> {
    let dataCopy = JSON.parse(JSON.stringify(data));
    const dataFiltered = dataCopy.filter(element =>{
    element.data = element.data.filter(entry => entry.x >= firstDate)
    return element;})
    var filteredEmpty = dataFiltered.filter(function(el) { return el.data.length !== 0; }); 
    return filteredEmpty;
    }

    