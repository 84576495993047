import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Card } from 'react-bootstrap';
import "../styles/Home.css";
import { Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { Nav, Navbar, Button, Container, Dropdown, DropdownButton } from 'react-bootstrap';


export const Home = () => {

  return (
    <>
      <AuthenticatedTemplate>
        <Navigate to='/prices' replace={true} />
        <Nav.Link className={window.location.pathname === "/prices" ? "navbarButton active" : "navbarButton"} href="/prices">
                            Preços
                            </Nav.Link>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
          <div className="bg-style" style={{ minHeight: '100vh', overflow: 'hidden' }}>
            <div className="container-fluid position-relative">
              <Card className="my-card position-absolute bottom-0 start-0 mb-4 ms-4">
                <Card.Body style={{ paddingBottom: '100px' }}>
                  <Card.Text className="titulo-personalizado">Appia Market</Card.Text>
                  <Card.Text className="texto-personalizado">
                    Faça compras inteligentes. <br />
                    Identifique pontos de compra, com previsão de preços e análise de tendências do mercado.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
      </UnauthenticatedTemplate>
    </>
  );
};
